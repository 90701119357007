<template>
  <div class="banner">
    <div class="back-img">
      <div class="content content-padding">
        <div class="font-40 text-020814">安全、高效的社区服务方案</div>
        <div class="font-weight-400 font-16 text-41464F margin-top-6 margin-bottom-9">业内领先的数智社区整体解决方案服务商</div>
        <div class="box">
            <div class="item-box animation">
              <a href="#">
                <div class="icon-box">
                  <img src="../../assets/images/banner1-icon1.png" alt="" class="icon-156">
                </div>
                <div class="margin-top-20 padding-lr-32 padding-bottom-56">
                  <div class="text-020814 font-24 font-weight-400">物业管理</div>
                  <div class="font-14 text-41464F font-weight-400 margin-top-20">14大业务子系统覆盖物业管理全生命周期</div>
                </div>
              </a>
            </div>
            <div class="item-box animation">
              <div class="icon-box">
                <img src="../../assets/images/banner1-icon2.png" alt="" class="icon-156">
              </div>
              <div class="margin-top-20 padding-lr-32 padding-bottom-56">
                <div class="text-020814 font-24 font-weight-400">生活服务</div>
                <div class="font-14 text-41464F font-weight-400 margin-top-20">为社区居民提供多样化生活服务， 打造便民惠民生活服务圈</div>
              </div>
            </div>
            <div class="item-box animation">
              <div class="icon-box">
                <img src="../../assets/images/banner1-icon3.png" alt="" class="icon-156">
              </div>
              <div class="margin-top-20 padding-lr-32 padding-bottom-56">
                <div class="text-020814 font-24 font-weight-400">康养医疗</div>
                <div class="font-14 text-41464F font-weight-400 margin-top-20">专业化的养老服务团队，提高居家养老服务整体质量</div>
              </div>
            </div>
            <div class="item-box animation">
              <div class="icon-box">
                <img src="../../assets/images/banner1-icon4.png" alt="" class="icon-156">
              </div>
              <div class="margin-top-20 padding-lr-32 padding-bottom-56">
                <div class="text-020814 font-24 font-weight-400">基层治理</div>
                <div class="font-14 text-41464F font-weight-400 margin-top-20">平台直连街道办系统，让社会治理与政务服务触达基层神经末梢</div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bannerComponent1',
  data () {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
.banner{
  position: relative;
}
.back-img{
  position: relative;
  background-image: url("../../assets/images/banner1-backimg.png");
  background-repeat: no-repeat;
  z-index: 1;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.content{
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}
.content-padding{
  padding-top: 80px;
  text-align: center;
}
.box{
  position: relative;
  //margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 48px;
  padding-bottom: 80px;
}

.item-box{
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px rgba(149,157,165,0.2);
  min-width: 256px;
  margin-top: 16px;
  margin-left: 16px;
  width: 23%;
}
.item-box::after{
  content: '';
  flex: 1;
}
.icon-box{
  background: linear-gradient(180deg, rgba(15,97,249,0.15) 0%, rgba(255,255,255,0) 100%);
}
a{
  text-decoration: none;
}

.animation {
  transition: All 0.4s ease-in-out;
  -webkit-transition: All 0.5s ease-in-out;
  -moz-transition: All 0.5s ease-in-out;
  -o-transition: All 0.5s ease-in-out;
}

.animation:hover {
  transform: translate(0, -32px);
  -webkit-transform: translate(0, -32px);
  -moz-transform: translate(0, -32px);
  -o-transform: translate(0, -32px);
  -ms-transform: translate(0, -32px);
}
</style>
