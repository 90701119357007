<template>
<div class="detail-page content">
  <context1></context1>
</div>
</template>
<script>
import context1 from '@/components/context/context1'
export default {
  name: 'detailPage',
  components: {
    context1
  }
}
</script>
<style lang="less" scoped>
.detail-page{
  position: relative;
}
.content{
  position: relative;
  margin: 0 auto;
  max-width: 906px;
  overflow: hidden;
}
</style>
