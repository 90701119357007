import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index'
import detail from '../views/detail'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    children: [
      {
        path: '/detail',
        name: 'detail',
        component: detail

      }
    ]
  }

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
