<template>
  <div class="index">
    <template v-if="$route.name === 'index'">
      <menu1></menu1>
      <banner class="anchor-item" id="home"></banner>
      <banner1 class="anchor-item" id="introduction"></banner1>
      <banner2 class="anchor-item" id="projectCase"></banner2>
      <banner3 class="anchor-item" id="newInfos"></banner3>
      <banner4 class="anchor-item" id="download"></banner4>
    </template>
    <template v-else>
      <menu2></menu2>
      <router-view/>
    </template>
    <div id="concatUS" class="anchor-item">
      <template v-if="$route.name === 'index'">
        <banner5></banner5>
      </template>
      <footer1></footer1>
    </div>
  </div>
</template>
<script>
import menu1 from '@/components/menus/menu1'
import menu2 from '@/components/menus/menu2'
import banner from '@/components/banner/banner'
import banner1 from '@/components/banner/banner1'
import banner2 from '@/components/banner/banner2'
import banner3 from '@/components/banner/banner3'
import banner4 from '@/components/banner/banner4'
import banner5 from '@/components/banner/banner5'
import footer1 from '@/components/footer/footer'
export default {
  name: 'indexPage',
  components: {
    menu1,
    menu2,
    banner,
    banner1,
    banner2,
    banner3,
    banner4,
    banner5,
    footer1
  },
  methods: {
  }

}
</script>
<style lang="less" scoped>
.el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align:center;
  line-height: 60px;
}
.el-header{
  padding: 0 ;
}
.el-aside::-webkit-scrollbar {
  display: none;

}

.el-aside {
  color: #333;
  text-align: center;
  line-height: 200px;
  overflow-y: hidden;
}

.el-main {
  height: max-content;
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}
.el-container{
  height: 100%;
}
</style>
