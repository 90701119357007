<template>
    <div class="footer container-xxl">
        <div class="content content-padding">
          <div class="box row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
            <div class="box-item col flex align-center">
              <img src="../../assets/images/footer-phone.png" alt="" class="icon-56">
              <div class="text-left margin-left-16">
                <div class="font-18 text-020814">电子邮件</div>
                <div class="font-14 text-41464F margin-top-8">louyan-0502@qq.com</div>
              </div>
            </div>
            <div class="box-item col flex align-center">
              <img src="../../assets/images/footer-location.png" alt="" class="icon-56">
              <div class="text-left margin-left-16">
                <div class="font-18 text-020814">联系电话</div>
                <div class="font-14 text-41464F margin-top-8">18212003868</div>
              </div>
            </div>
            <div class="box-item col flex align-center">
              <img src="../../assets/images/footer-new.png" alt="" class="icon-56">
              <div class="text-left margin-left-16">
                <div class="font-18 text-020814">详细地址</div>
                <div class="font-14 text-41464F margin-top-8">贵阳市威清路中建四局大厦5楼</div>
              </div>
            </div>
          </div>
            <div class="border-bottom"></div>
            <div class="margin-top-16 bottom-flex">
              <span class="font-weight-40 font-12 text-41464F">©2023 - 贵州惠智电子技术有限责任公司版权所有</span>
              <span class="margin-lr-8 font-weight-40 font-12 text-41464F">|</span>
              <span class="font-weight-40 font-12 text-41464F">备案号：黔ICP备17010536号-4</span>
              <span class="margin-lr-8 font-weight-40 font-12 text-41464F">|</span>
              <span class="font-weight-40 font-12 text-41464F">贵公网安备案号：52010302000130号</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'footerComponent1'
}
</script>
<style lang="less" scoped>
.content{
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}
.content-padding{
  padding-top: 80px;
  padding-bottom: 20px;
  text-align: center;
}
  .box{
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .border-bottom{
    border-bottom: 1px solid rgba(158, 165, 184, 0.45);
    position: relative;
    margin: 0 auto;
    padding-top: 80px;
  }
  .bottom-flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
