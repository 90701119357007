<template>
  <div>
   <div class="margin-center">
     <swiper :options="swiperOption" ref="mySwiper">
       <swiper-slide v-for="item in articleList" :key="item.aid" class="slider-item ">
         <div class="cursor"  @click="goDetail(item)">
           <div style="position: relative;">
             <img :src="baseUrl+item.litpic" alt=""  class="item-img animation">
             <div class="time">{{item.add_time}}</div>
           </div>
           <div class="title">
             {{item.title}}
           </div>
         </div>
       </swiper-slide>
     </swiper>
   </div>
    <div class="swiper-button-prev">
    </div>
    <div class="swiper-button-next">
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { getList } from '@/axios/api'
export default {
  props: {},
  name: 'swiperConponent1',
  components: {
    swiper,
    swiperSlide
  },
  data () {
    return {
      articleList: [],
      baseUrl: 'http://wp.lwjservice.cn/',
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 24,
        updateOnImagesReady: true,
        resizeReInit: true,
        roundLengths: true,
        freeMode: true,
        setWrapperSize: true,
        parallax: true,
        breakpointsInverse: true,
        grid: {
          fill: 'row',
          row: 1
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1280: { // 当屏幕宽度小于等于1280
            slidesPerView: 3,
            spaceBetween: 30
          },
          768: { // 当屏幕宽度小于等于768
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: { // 当屏幕宽度小于等于320
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    goDetail (item) {
      this.$router.push({ path: '/detail', query: { tab: '新闻详情', aid: item.aid, title: item.title } })
    },
    getList () {
      getList().then(res => {
        this.articleList = res.data.article_list
        this.articleList.forEach(item => {
          item.add_time = this.formatDate(item.add_time)
        })
      })
    },
    formatDate (data) {
      const date = new Date(data * 1000)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) { month = '0' + month }
      if (day < 10) { day = '0' + day }
      return year + '-' + month + '-' + day + ''
    }
  }
}
</script>

<style scoped lang="less">
.swiper-button-prev{
  width: 40px;
  height: 40px;
  background-image:url("../../assets/images/swiper-left.svg");
  box-shadow: 0 8px 24px 0 hsl(210deg 69.84% 14.65%);
  border-radius: 50%;
}
.swiper-button-next{
  width: 40px;
  height: 40px;
  background-image:url("../../assets/images/swiper-right.svg");
  box-shadow: 0 4px 22px 0 rgb(7 12 20 / 20%);
  border-radius: 50%;
}
.box{
  position: relative;
}
.bg-img{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
  width: 200px;
  height: 150px;
}
.time{
  position: absolute;
  top:auto;
  bottom: 4px;
  left: 0;
  right: 0;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  background: rgba(0,0,0,0.8);
  width: 84px;
  height: 24px;
  line-height: 24px;
  padding-left: 16px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 8px;
}
.slider-item{
  position: relative;
  overflow: hidden;
  text-align: left;
}
.item-img{
  width: 100%;
  height: 200px;
  border-radius: 8px 8px 8px 8px;
  position: relative;
}
.title{
  margin-top: 24px;
  cursor: pointer;
}
.title{
  text-decoration: none;
  font-size: 18px;
  color: #020814 ;
}

.animation {
  transition: All 0.5s ease-in-out;
  -webkit-transition: All 0.5s ease-in-out;
  -moz-transition: All 0.5s ease-in-out;
  -o-transition: All 0.5s ease-in-out;
  padding: 32px 0;
}

.animation:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  .title{
    color: #165DFF;
  }
}
.margin-center{
  width: 95%;
  position: relative;
  margin: 0 auto;
}
.swiper-container{
  padding: 0 36px;
}
</style>
