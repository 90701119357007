<template>
    <div class="banner bg-image">
        <div class="content content-padding media-content">
            <div class="font-40 text-FFFFFF">数智社区服务就选惠智</div>
           <div class="box">
             <div class="margin-top-12 font-14 text-FFFFFF">贵州惠智电子技术有限责任公司位于中国贵州爽爽的贵阳，成立于1993年，注册资金1006万，现有员工近200 人，旗下有贵州惠智电子技术有限责任公司、贵阳硕鸿软件技术、贵阳思力克软件、贵州惠腾智实信息技术、贵州任行天下科技、贵州企企科技、贵州中科智教科技发展、 贵州惠智职业培训学校。
                是贵州最早获得系统集成三级资质、涉密信息系统集成乙级资质及“双软认证”资质的高新技术企业，连续二十多年被评为省级 “守合同重信用” 单位。
            </div>
            <div class="margin-top-12 font-14 text-FFFFFF">
                数智社区事业部作为惠智集团旗下的独立事业部，聚焦于城乡社区基层治理及生活服务，为政府、企业、居民提供集基层社会治理、城乡社区服务、社区长效可持续发展于一体的全方位的解决方案，打造安全、便捷、智能的社区综合数字化空间，提升居民获得感、幸福感和安全感。
            </div>
           </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'bannerComponent5'
}
</script>
<style lang="less" scoped>
.banner{
    position: relative;
}
.content{
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}
.content-padding{
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
}
  .bg-image{
    position: relative;
    background-image: url("../../assets/images/banner5-bg.png");
    background-repeat: no-repeat;
    z-index: 1;
    background-size: cover;
    background-position: center;
  }
  .box{
    position: relative;
    margin: 0 auto;
    line-height: 2;
  }
</style>
