<template>
<!-- :style="{minWidth:width+'px',maxWidth:'2560px'}"-->
  <div id="app" >
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'APP',
  data () {
    return {
      width: '600'
    }
  },
  mounted () {
    this.width = this.$el.clientWidth
    console.log(this.$el.clientWidth, 'ddd')
  }
}
</script>

<style>
@import "./assets/css/common.css";
</style>

<style>
html, body, #app {
  padding: 0;
  margin: 0;
  position: relative;
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}
#app{
  height: 100vh;
}
</style>
