<template>
    <div class="banner bg-image" >
        <div class="content content-padding container-xxl">
            <div class="text-020814 font-40">应用下载</div>
            <div class="margin-top-6 font-weight-400 font-16 text-41464F">助力客户快速完成数字化转型</div>
            <div class="box">
              <div class="margin-top-64 row align-items-start">
                <div class="text-left media-text-center col-12 col-lg-6 col-md-6 ">
                  <img src="../../assets//images//banner4-logo.png" alt="" class="icon-80">
                  <div class="font-28 text-020814 margin-top-32">惠智邻里</div>
                  <div class="text-41464F font-weight-400 font-16 margin-top-24">科技向善，连接用户与社区</div>
                  <div class="text-41464F font-weight-400 font-16 margin-top-24">为社区用户提供物业为主，生活为辅的数智化服务，</div>
                  <div class="text-41464F font-weight-400 font-16 margin-top-24">同时协助街道办事处进行基层治理。</div>
                  <div class="margin-top-64 flex">
                    <div class="icon-ios">
                      <el-popover
                        popper-class="bg-color"
                        placement="right-end"
                        width="200"
                        trigger="hover"
                      >
                        <div class="text-center">
                          <img src="../../assets/images/downloadCode.png" alt="" class="icon-170">
                          <p class="text-FFFFFF"> 扫码下载惠智邻里app 体验更多功能。</p>
                        </div>
                        <el-button slot="reference" round class="btn-hover">
                          <a href="https://apps.apple.com/cn/app/%E6%83%A0%E6%99%BA%E9%82%BB%E9%87%8C/id6471039404" target="_blank">
                            <img src="../../assets/images/ios-icon-black.png" alt="" class="icon-16 " >
                            <span class="text-020814 font-weight-400 font-16 margin-left-2">ios下载</span>
                          </a>
                        </el-button>
                      </el-popover>
                    </div>
                    <div class="margin-left-24 icon-android" >
                      <el-popover
                        popper-class="bg-color"
                        placement="right-end"
                        width="200"
                        trigger="hover">
                        <div class="text-center">
                          <img src="../../assets/images/downloadCode.png" alt="" class="icon-170">
                          <p class="text-FFFFFF"> 扫码下载惠智邻里app 体验更多功能。</p>
                        </div>
                        <el-button slot="reference" round class="btn-hover" >
                          <a href="https://url.cloud.huawei.com/oadOnQv4eA?shareTo=qrcode" target="_blank">
                            <img src="../../assets/images/android-icon-black.png" alt="" class="icon-16 ">
                            <span class="font-16 text-020814 font-weight-400 margin-left-2">安卓下载</span>
                          </a>
                        </el-button>
                      </el-popover>
                    </div>
                  </div>
                </div>
                <img src="../../assets//images/banner4-phone.png" alt="" class="img-phone col-12 col-lg-6 col-md-6 " >
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'bannerComponent4'
}
</script>
<style lang="less">
.bg-color{
  background: rgba(0,0,0,0.84) !important;
  box-shadow: 0px 7px 11px 0px rgba(0,0,0,0.1) !important;
  border-radius: 12px 12px 12px 12px !important;
}
.bg-color[x-placement^=right] .popper__arrow::after{
  border-right-color:rgba(0,0,0,0.84) !important;
}
</style>

<style lang="less" scoped>
.content{
  position: relative;
  margin: 0 auto;
  max-width: 1200px !important;
}
.content-padding{
  padding-top: 80px;
  padding-bottom: 120px;
  text-align: center;
  overflow: hidden;
}
  .bg-image{
    position: relative;
    background-image: url("../../assets/images/banner4-bg.png");
    background-repeat: no-repeat;
    z-index: 1;
    background-size: cover;
    background-position: center;
  }
  .img-phone{
    max-width: 480px;
    max-height: 584px;
    min-width: 350px;
    //padding: 0 32px;
  }
  .width-25{
    width: 25%;
  }
  .icon-ios:hover{
    img{
      content: url("../../assets/images/ios-icon-white.png");
    }
  }
  .icon-android:hover{
   img{
     content: url("../../assets/images/android-icon-white.png");
   }
  }
  .btn-hover:hover{
    background: #165DFF;
    span{
      color: #FFFFFF !important;
    }
  }
  //.box{
  //  position: relative;
  //  margin: 0 auto;
  //  overflow-x: auto;
  //}
  a{
    text-decoration: none;
  }
  //.margin-left-15-per{
  //  margin-left: 15%;
  //}
</style>
