<template>
  <div class="banner">
    <div class="bg-image">
      <div class="bg-container">
        <div class="context margin-center media-content">
          <div class="text-020814 font-42">数智社区整体解决方案提供商</div>
          <div class="margin-top-12">
            <span class="context-span">物业管理</span>
            <span class="margin-lr-5 context-span">|</span>
            <span class="context-span">生活服务</span>
            <span class="margin-lr-5 context-span">|</span>
            <span class="context-span">康养医疗</span>
            <span class="margin-lr-5 context-span">|</span>
            <span class="context-span">基层治理</span>
          </div>
          <div class="margin-top-36">
            <el-button type="primary" class="bg-165DFF text-FFFFFF btn-pd-1" @click="download">立即体验</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bannerComponent',
  methods: {
    download () {
      document.getElementById('download').scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>
<style lang="less" scoped>
.bg-image{
  position: relative;
  background-image: url("../../assets/images/top-bg.png");
  background-repeat: no-repeat;
  min-height: 540px;
  z-index: 1;
  background-size: cover;
  background-position: center;
}
.bg-container{
  min-height: 540px;
  display: flex;
  align-items: center;
}
.context{
  width: 1200px;
  max-width: 1200px;
}
.margin-center{
  position: relative;
  margin: 0 auto;
}
.context-span{
  font-weight: 400;
  font-size: 16px;
  color: #41464F;
}
a{
  text-decoration: none;
  font-size: 16px;
}
.btn-pd-1{
padding: 14px 34px !important;
}
</style>
