<template>
  <div class="bg-white-65 menu1 ">
    <div class="header" :class="{'bg-white':bgChange}">
      <div class="content header-group media-content">
      <div class="flex align-center nowrap cursor" @click="goIndex">
        <img src="../../assets/images/logo.png" alt="" class="logo-img">
        <span class="font-weight-700 text-3F3B3A font-18">数智社区事业部</span>
      </div>
      <div>
        <ul class="menu-nav">
          <li v-for="(item,index) in menuList"
              :class="{'nav-active border-bottom-1':activeIndex === index+1}"
              :key="item.titleId" @click="goAnchor(item.id,index+1)">
            <div>{{item.title}}</div>
          </li>
        </ul>
      </div>
      <div class="flex align-center">
        <img src="../../assets/images/phone-black.png" alt="" class="icon-20">
        <span class="font-weight-400 text-41464F font-16">18212003868</span>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'menuComponent1',
  data () {
    return {
      activeIndex: 1,
      elList: [],
      menuList: [{ id: '#home', title: '主页', titleId: '1' }, { id: '#introduction', title: '业务介绍', titleId: '2' },
        { id: '#projectCase', title: '项目案例', titleId: '3' }, { id: '#newInfos', title: '新闻动态', titleId: '4' },
        { id: '#download', title: '应用下载', titleId: '5' }, { id: '#concatUS', title: '联系我们', titleId: '6' }],
      bgChange: false,
      scrollTop: 0
    }
  },
  created () {},
  mounted () {
    this.getDocument()
    window.addEventListener('scroll', this.scrollTopContent)
  },
  methods: {
    getDocument () {
      this.$nextTick(() => {
        const el = document.getElementsByClassName('index')[0]
        this.elList = el.children
      })
    },
    goAnchor (selector, index) {
      if (this.activeIndex === index) return
      this.activeIndex = index
      this.elList[parseInt(index)].scrollIntoView({ behavior: 'smooth' })
    },
    scrollTopContent () {
      // 所有锚点元素的 offsetTop
      const offsetTopArr = []
      const navContents = document.querySelectorAll('.anchor-item')
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop)
      })
      const scrollTop = document.documentElement.scrollTop
      this.scrollTop = scrollTop
      // 获取当前文档流的 scrollTop
      offsetTopArr.forEach((item, index) => {
        if (scrollTop >= item) {
          this.activeIndex = index + 1
        }
      })
    },
    goIndex () {
      this.activeIndex = 1
      this.elList[1].scrollIntoView({ behavior: 'smooth' })
    }
  },
  watch: {
    scrollTop (newVal) {
      if (newVal > 10) {
        this.$nextTick(() => {
          this.bgChange = true
        })
      }
      if (newVal === 0) {
        this.$nextTick(() => {
          this.bgChange = false
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.logo-img{
width: 40px;
  height: 40px;
}
.menu1{
  position: relative;
}
.menu-nav{
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0!important;
  li{
    list-style: none;
    padding: 0 16px;
    cursor: pointer;
  }
}
.nav-active{
  color: #165DFF;
}
.border-bottom-1{
  border-bottom: 2px solid #165DFF;
  line-height: 62px;
  margin-bottom: 2px;
}
a{
  text-decoration: none;
  font-size: 16px;
}
.header{
  //position: absolute;
  position: fixed;
  top: 0;
  max-height: 64px;
  width: 100%;
  background: rgba(255,255,255,0.65);
  z-index: 2;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.header-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 900px){
  .menu-nav{
    display: none;
  }
}
.bg-white{
  background: #FFFFFF !important;
  border: 1px solid #E7E8ED !important;
}
.content{
  width: 1200px;
  max-width: 1200px;
}
</style>
