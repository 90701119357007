<template>
  <div class="content-padding margin-center media-content">
   <div>
     <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
       <el-breadcrumb-item>{{$route.query.tab}}</el-breadcrumb-item>
     </el-breadcrumb>
   </div>
    <div class="context-box text-left">
      <div class="font-32 text-020814 margin-top-32">{{articleDetail.title}}</div>
      <div class="margin-top-16 margin-bottom-32 flex align-center">
        <img src="../../assets/images/clock-icon.png" alt="" class="icon-16 margin-right-5">
        <span class="text-41464F font-14 font-weight-400">{{articleDetail.add_time}}</span>
      </div>
      <div class="row api-img">
          <div v-html="articleDetail.content"></div>
      </div>
    </div>
    <div class="padding-top-56">
      <el-button type="primary" class="blue-line" @click="goBack">返回上一级</el-button>
    </div>
  </div>
</template>
<script>
import { getDetail } from '@/axios/api'
export default {
  name: 'contextComponent1',
  data () {
    return {
      articleDetail: {},
      aid: ''
    }
  },
  mounted () {
    this.aid = this.$route.query.aid
    this.getDetail()
  },
  methods: {
    goBack () {
      this.$router.push({ path: '/' })
    },
    getDetail () {
      getDetail(this.aid).then(res => {
        this.articleDetail = res.data.article_detail
        this.articleDetail.add_time = this.formatDate(this.articleDetail.add_time)
        this.articleDetail.update_time = this.formatDate(this.articleDetail.update_time)
        this.articleDetail.title = this.$route.query.title
      })
    },
    formatDate (data) {
      const date = new Date(data * 1000)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (month < 10) { month = '0' + month }
      if (day < 10) { day = '0' + day }
      return year + '-' + month + '-' + day + ''
    }
  }
}
</script>
<style lang="less" scoped>
.content-padding{
  padding-top: 32px;
  padding-bottom: 80px;
  text-align: center;
  overflow: hidden;
}
.blue-line{
  background: #FFFFFF !important;
  color: #165DFF !important;
  font-weight: 400;
  font-size: 14px !important;
}
.margin-right-5{
  margin-right: 5px;
}
.context-box{
  min-height: 350px;
}
.api-img{
 /deep/ img{
    width: 100% !important;
  }
  /deep/ div{
    width: 100% !important;
  }
  /deep/ p{
    width: 100% !important;
  }
 /deep/ .detailprizecard{
    width: 100% !important;
  }
}

</style>
