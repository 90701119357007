<template>
  <div class="banner bg-white" >
    <div class="content content-padding">
      <div class="text-020814 font-40">了解数智社区最新动态</div>
      <div class="font-weight-400 font-16 text-41464F margin-top-6">关注最新动态，读懂社区未来</div>
      <div class="box padding-top-64">
        <swiper></swiper>
      </div>
    </div>
  </div>
</template>
<script>
import swiper from '@/components/swiper/swiper1'
export default {
  name: 'banerComponent3',
  components: {
    swiper
  },
  data () {
    return {
    }
  },
  mounted () {

  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.banner{
  position: relative;
}
.content{
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}
.content-padding{
  padding-top: 80px;
  padding-bottom: 120px;
  text-align: center;
}
.box{
  width: 100%;
}
</style>
