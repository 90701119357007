<template>
  <div class="menu border-bottom">
    <div class="content  media-content">
    <div class="padding-tb-12 flex align-center row">
      <div class="flex align-center nowrap col-12 col-md-6">
        <div  @click="goIndex" class="flex align-center cursor">
          <img src="../../assets/images/logo.png" alt="" class="logo-img">
          <span class="font-weight-700 text-3F3B3A font-18">数智社区事业部</span>
        </div>
        <div>
          <span class="margin-lr-8">|</span>
          <span>
          {{$route.query.tab}}
        </span>
        </div>
      </div>
      <div class="col-12 col-md-6 text-end-1">
        <div class="">
          <img src="../../assets/images/phone-black.png" alt="" class="icon-20">
          <span class="font-weight-400 text-41464F font-16">18212003868</span>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'menuComponent2',
  methods: {
    goIndex () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
<style lang="less" scoped>
.logo-img{
  width: 40px;
  height: 40px;
}
.border-bottom{
  border-bottom: 1px solid rgba(158,165,184,0.35);
}
.content{
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
}

</style>
