<template>
  <div class="banner bg-image" >
    <div class="content content-padding">
      <div class="text-FFFFFF font-40">来自全国用户的优秀实践</div>
      <div class="font-weight-400 text-FFFFFF font-16 margin-top-6">客户真实案例，借鉴成功经验，提高选型效率</div>
      <div class="box">
        <div class="left">
          <el-button type="primary" class="bg-165DFF border-none btn-pd">贵阳广大城</el-button>
          <div class="font-16 text-FFFFFF font-weight-400 margin-top-21">更多项目尽情期待</div>
        </div>
        <div class="right flex">
          <div class="text-FFFFFF padding-top-48 padding-lr-56 text-left">
            <div class="font-20 ">贵阳广大城小区</div>
            <div class="border margin-tb-16"></div>
            <div class="font-14 font-weight-400">团队为贵阳广大城小区提供软硬件一体化的整体解决方案、7*12小时的不间断服务、1小时到达现场的即时运维服务。</div>
            <div class="icon-box margin-top-64 flex align-center justify-space-between nowrap">
              <div class="flex align-center">
                <img src="../../assets/images/banner2-icon1.png" alt="" class="icon-40">
                <div >
                  <div class="font-16 text-FFFFFF">20栋</div>
                  <div class="font-12 font-weight-400 text-FFFFFF-75 margin-top-4">规划楼栋</div>
                </div>
              </div>
              <div class="flex align-center">
                <img src="../../assets/images/banner2-icon2.png" alt="" class="icon-40">
                <div >
                  <div class="font-16 text-FFFFFF">2500户</div>
                  <div class="font-12 font-weight-400 text-FFFFFF-75 margin-top-4">规划户数</div>
                </div>
              </div>
              <div class="flex align-center">
                <img src="../../assets/images/banner2-icon3.png" alt="" class="icon-40">
                <div >
                  <div class="font-16 text-FFFFFF">3百万㎡</div>
                  <div class="font-12 font-weight-400 text-FFFFFF-75 margin-top-4">占地面积</div>
                </div>
              </div>
              <div class="flex align-center">
                <img src="../../assets/images/banner2-icon4.png" alt="" class="icon-40">
                <div >
                  <div class="font-16 text-FFFFFF">7百万㎡</div>
                  <div class="font-12 font-weight-400 text-FFFFFF-75 margin-top-4">建筑面积</div>
                </div>
              </div>
            </div>
          </div>
          <img src="../../assets/images/banner2-img.png" alt="" class="banner-img">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bannerComponent2'
}
</script>
<style lang="less" scoped>
.banner{
  position: relative;
}
.content{
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}
.content-padding{
  padding-top: 80px;
  padding-bottom: 120px;
  text-align: center;
}
.bg-image{
  position: relative;
  background-image: url("../../assets/images/banner2.png");
  background-repeat: no-repeat;
  z-index: 1;
  background-size: cover;
  background-position: center;
}
.box{
  position: relative;
  margin: 0 auto;
  margin-top: 64px;
  border-radius: 8px 8px 8px 8px;
  background: rgba(255,255,255,0.05);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.left{
  background: rgba(255,255,255,0.15);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 48px;
  white-space: nowrap;
}
.banner-img{
  max-width: 256px;

}
.border{
  opacity: 0.35;
  border-bottom: 1px solid #FFFFFF;
}
.border-none{
  border:none !important;
}
.btn-pd{
  padding: 12px 72px !important;
  font-size: 16px !important;
  font-weight: 400;
}
</style>
